import "./style.css";
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table/react-table.css";
import 'react-image-lightbox/style.css';
import "video.js/dist/video-js.css";


import { isMultiColorActive, themeColorStorageKey,isDarkSwitchActive,defaultApp } from "./constants/defaultValues";
import CONFIG from './CONFIG.json'

let render = () => {
  let app = defaultApp
  const params = new URL(window.location.href).searchParams;
  const status = params.get('status');
  const response_text = params.get('response_text');
  const confirm_number = params.get('confirm_number');
  localStorage.setItem('order_status',status)
  localStorage.setItem('response_text',response_text)
  localStorage.setItem('confirm_number',confirm_number)
  if(window.location.host !== "localhost:3000"  &&  window.location.host !== "192.168.1.11:3000"){
    app = window.location.host.split('.')[1]
  }

  let color =
    (isMultiColorActive||isDarkSwitchActive ) && localStorage.getItem(themeColorStorageKey)
      ? localStorage.getItem(themeColorStorageKey)
      : CONFIG[app].theme_color;
      if(color.length<5){
        color = CONFIG[app].theme_color
      }

  localStorage.setItem(themeColorStorageKey, color);

  import('./assets/css/sass/themes/gogo.' + color + '.scss').then(x => {
     require('./AppRenderer');
  });

};
render();
