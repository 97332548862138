import React, { Component } from "react";

import CONFIG_JSON from '../CONFIG.json'
import { defaultApp } from "../constants/defaultValues";

export default class Loader extends Component {
  render(){
    let app = defaultApp
    if(window.location.host !== "localhost:3000"  &&  window.location.host !== "192.168.1.11:3000"){
      app = window.location.host.split('.')[1]
    }

    return(
      <div>
        <img className="loader" src={"../"+CONFIG_JSON[app].logo} alt={CONFIG_JSON[app].name}/>
        <div className="loading">
        </div>
      </div>
    )
  }
}
